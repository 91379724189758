* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.header-tab {
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  position: fixed; 
  top: 0;
  left: 50%;
  z-index: 1000;
  width: 100%; 
  height: 60px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  align-items: center;
  transform: translateX(-50%);
  transition: all 0.4s ease;
}

.nav.shrink {
  width: 90%;
  max-width: 1200px;
  margin-top: 15px;
  height: 50px;
  border-radius: 5px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* border:1px solid rgba(255, 255, 255, 0.18); */
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

.site-title {
  font-size: 1.6rem;
  color: grey;
  font-weight: bold;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: white;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
  position: relative;
}

.nav a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: 0rem;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.nav li.active a::after {
  background-color: white;
}

.nav li:hover a::after {
  background-color: white;
}